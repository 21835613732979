const appMenuData =
[
  {
    title: '퀴즈 베너관리',
    id: 'QUIZ_BANNER',
    menuIcon: '🖼',
    routerPath: 'APP_QUIZ_BANNER',
  },
]

export { appMenuData }