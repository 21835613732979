const commonData = {
  break_point: 1024,
  service_name:'firfin',
  admin_name:'content admin',
  isSnbRoleBadge: true,
  name_letter_spacing : {
    header:{
      service: '0.04em',
      admin: '0.02em',
    },
    login:{
      service: '0em',
      admin: '0.1em',
    }
  }
}

export { commonData }