import { worldMapMenuData } from '@/constants/worldMapMenuData';
import { stockMenuData } from '@/constants/stockMenuData';
import { appMenuData } from '@/constants/appMenuData';

import { adminMenuData } from '@/constants/adminMenuData';
import { guideMenuData } from '@/constants/guideMenuData';

const menuData =
[
    { 
      title: '콘텐츠 관리',
      foldTitle: '콘텐츠',
      id: 'WORLD_MAP',
      menuList: worldMapMenuData,
      isFold: false,
    },
    { 
      title: '주식 관리',
      foldTitle: '주식',
      id: 'STOCK',
      menuList: stockMenuData,
      isFold: false,
    },
    { 
      title: '앱 관리',
      foldTitle: '앱',
      id: 'APP',
      menuList: appMenuData,
      isFold: false,
    },
    { 
      title: '어드민 관리',
      foldTitle: '어드민',
      id: 'ADMIN',
      menuList: adminMenuData,
      requiresMinRole: 'ADMIN',
      isFold: false,
    },
    { 
      title: 'Guide',
      foldTitle: 'Guide',
      id: 'GUIDE',
      menuList: guideMenuData,
      isFold: true,
      isOnlyDev: true,
    },
]

export { menuData }