const routerPath = {
  HOME: '/',
  LOGIN: '/login',
  MY: '/my',

  // --- content ---
  // 장소관리
  WORLD_MAP_PLACE_LIST: '/content/place/list',
  // 콘텐츠관리
  WORLD_MAP_CONTENT_FOLDER: '/content/folder',
  WORLD_MAP_ORDER: '/content/order',
  // 태그관리
  WORLD_MAP_TAG: '/content/tag',

  // 주식종목관리
  STOCK_DETAIL: '/stock/detail',

  STOCK_GAME_LIST: '/stock/game/list',
  STOCK_GAME_DETAIL: '/stock/game/detail/:id',
  STOCK_GAME_REGISTER: '/stock/game/register',
  STOCK_GAME_MODIFY: '/stock/game/modify/:id',

  // 앱관리
  APP_QUIZ_BANNER: '/app/quiz-banner',

  // --- admin ---
  // 직원관리
  ADMIN_MEMBER_LIST: '/admin/member/list',
  ADMIN_MEMBER_DETAIL: '/admin/member/detail/:id',
  ADMIN_MEMBER_REGISTER: '/admin/member/register',
  ADMIN_MEMBER_MODIFY: '/admin/member/modify/:id',

  //  --- guide ---
  GUIDE_COMPONETS_FORMS: '/guide/components/forms',
  GUIDE_COMPONETS_TAB: '/guide/components/tab',
  GUIDE_COMPONETS_SEARCH: '/guide/components/search',
  GUIDE_COMPONETS_BOARD: '/guide/components/board',
  GUIDE_COMPONETS_TABLE: '/guide/components/table',
  GUIDE_COMPONETS_CATEGORY: '/guide/components/category',
  GUIDE_COMPONETS_CALENDAR: '/guide/components/calendar',
  GUIDE_COMPONETS_BUTTONS: '/guide/components/buttons',
  GUIDE_COMPONETS_BADGES: '/guide/components/badges',
  GUIDE_COMPONETS_WIDGETS: '/guide/components/widgets',
  GUIDE_COMPONETS_STEP: '/guide/components/step',
  GUIDE_ICONS: '/guide/icons',
  GUIDE_CHARTS: '/guide/charts',
  GUIDE_LAYER: '/guide/layer',
};

export default routerPath;