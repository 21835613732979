const worldMapMenuData =
[
  // {
  //   title: '콘텐츠공지',
  //   id: 'NOTICE',
  //   menuIcon: '📢',
  //   routerPath: 'CONTENTS_NOTICE_LIST',
  // },
  // {
  //   title: '장소관리',
  //   id: 'PLACE',
  //   menuIcon: '🗺',
  //   routerPath: 'WORLD_MAP_PLACE_LIST',
  // },
  {
    title: '지도 콘텐츠',
    id: 'MAP_CONTENT',
    menuIcon: '🗺',
    // routerPath: 'WORLD_MAP_CONTENT_FOLDER',
    menuList: [ 
      {
        title: '콘텐츠관리',
        id: 'FOLDER',
        requiresMinRole: 'ADMIN',
        routerPath: 'WORLD_MAP_CONTENT_FOLDER',
      },
      {
        title: '목록 순서',
        id: 'ORDER',
        requiresMinRole: 'ADMIN',
        routerPath: 'WORLD_MAP_ORDER',
      },
    ]
  },
  {
    title: '태그관리',
    id: 'TAG',
    menuIcon: '🏷️',
    routerPath: 'WORLD_MAP_TAG',
  },
]

export { worldMapMenuData }