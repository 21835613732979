const routerComponent = {
  LOGIN : () => import('@/views/login/view/Login.vue'),
  MY: () => import('@/views/my/view/My.vue'),

  //  contents
  // WORLD_MAP_PLACE_LIST: () => import('@/views/worldMap/place/view/PlaceList.vue'),
  WORLD_MAP_CONTENT_FOLDER: () => import('@/views/worldMap/contentFolder/view/ContentFolder.vue'),
  WORLD_MAP_ORDER: () => import('@/views/worldMap/mapOrder/view/MapOrder.vue'),
  WORLD_MAP_TAG: () => import('@/views/worldMap/tag/view/Tag.vue'),

  // 주식관리
  STOCK_DETAIL : () => import('@/views/stock/detail/view/StockDetail.vue'),

  STOCK_GAME_LIST : () => import('@/views/stock/game/view/StockGameList.vue'),
  STOCK_GAME_DETAIL : () => import('@/views/stock/game/view/StockGameDetail.vue'),
  STOCK_GAME_REGISTER : () => import('@/views/stock/game/view/StockGameRegister.vue'),
  STOCK_GAME_MODIFY : () => import('@/views/stock/game/view/StockGameModify.vue'),

  // 앱관리
  APP_QUIZ_BANNER : () => import('@/views/app/quizBanner/view/QuizBanner.vue'),


  // --- admin ---
  // 직원관리
  ADMIN_MEMBER_LIST: () => import('@/views/admin/member/view/MemberList.vue'),
  ADMIN_MEMBER_DETAIL: () => import('@/views/admin/member/view/MemberDetail.vue'),
  ADMIN_MEMBER_REGISTER: () => import('@/views/admin/member/view/MemberRegister.vue'),
  ADMIN_MEMBER_MODIFY: () => import('@/views/admin/member/view/MemberModify.vue'),

  //  guide
  GUIDE_COMPONETS_FORMS: () => import('@/views/guide/components/forms/Forms.vue'),
  GUIDE_COMPONETS_TAB: () => import('@/views/guide/components/tab/Tab.vue'),
  GUIDE_COMPONETS_SEARCH: () => import('@/views/guide/components/search/Search.vue'),
  GUIDE_COMPONETS_BOARD: () => import('@/views/guide/components/board/Board.vue'),
  GUIDE_COMPONETS_TABLE: () => import('@/views/guide/components/table/Table.vue'),
  GUIDE_COMPONETS_CATEGORY: () => import('@/views/guide/components/category/Category.vue'),
  GUIDE_COMPONETS_CALENDAR: () => import('@/views/guide/components/calendar/Calendar.vue'),
  GUIDE_COMPONETS_BUTTONS: () => import('@/views/guide/components/buttons/Buttons.vue'),
  GUIDE_COMPONETS_BADGES: () => import('@/views/guide/components/badges/Badges.vue'),
  GUIDE_COMPONETS_WIDGETS: () => import('@/views/guide/components/widgets/Widgets.vue'),
  GUIDE_COMPONETS_STEP: () => import('@/views/guide/components/step/Step.vue'),
  GUIDE_ICONS: () => import('@/views/guide/icons/Icons.vue'),
  GUIDE_CHARTS: () => import('@/views/guide/charts/chart/Chart.vue'),
  GUIDE_LAYER: () => import('@/views/guide/layer/Layer.vue'),
}

export default routerComponent;