import { colorDataList } from '@/constants/colorDataList'

// (더미)상태
export const dummyStatusDataList = [
  { id:'TODO', text:'할일' },
  { id:'DOING', text:'진행중' },
  { id:'DONE', text:'완료' },
  { id:'HOLD', text:'보류' },
]

// (더미)상태2
export const dummy2StatusDataList = [
  { id:'High', text:'높음' },
  { id:'Normal', text:'보통' },
  { id:'Light', text:'낮음' },
  { id:'None', text:'없음' },
]

// 스케줄 타입 // APP, BANK, CONTENT, PAGE
export const schedule_type = [
  { id:'GENERAL', text:'공통' },
  { id:'CONTENT', text:'콘텐츠' },
  { id:'APP', text:'App' },
  { id:'PAGE', text:'Landing Page' },
  { id:'BANK', text:'은행' },
]

export const common_status = [
  { id:'REGISTERED', text:'활성' },
  { id:'UNREGISTERED', text:'비활성' },
]

// 지도 > 맵타입
export const map_type = [
  // { id:'FREE', text:'무료지도'},
  { id:'PLUS', text:'유료지도'},
  { id:'SPONSOR', text:'스폰서지도'},
]
// 지도 > 용돈외의 보상타입
export const map_extra_reward_type = [
  { id:'COUPON', text:'쿠폰'},
  { id:null, text:'없음'},
]

// 콘텐츠 상태
export const contents_status = [
  { id:'DRAFT', text:'Draft' },
  { id:'PUBLIC', text:'Public' },
  { id:'CLOSED', text:'Closed' },
]
// 코스
export const courseMapDataList = [
  { id:0, text:'미분류'},
  { id:'HOME', text:'집'},
  { id:'RECYCLING_SITE', text:'재활용장'},
  { id:'BANK', text:'은행'},
  { id:'SHOP', text:'상점'},
  { id:'FLEA_MARKET', text:'벼룩시장'},
  { id:'MART', text:'마트'},
  { id:'CONVENIENCE_STORE', text:'편의점'},
  { id:'RESTAURANT', text:'식당'},
  { id:'CARD_COMPANY', text:'카드사'},
  { id:'DEPARTMENT_STORE', text:'백화점'},
  { id:'APARTMENT', text:'아파트'},
  { id:'CAR', text:'차'},
  { id:'LEMONTREE_COMPANY', text:'레몬트리 회사'},
  { id:'FACTORY', text:'공장'},
  { id:'AIRPLANE', text:'비행기'},
  { id:'POLICE_OFFICE', text:'경찰서'},
  { id:'SCHOOL', text:'학교'},
  { id:'STOCK_MARKET', text:'주식시장'},
  { id:'STOCK_COMPANY', text:'증권회사'},
  { id:'MINE', text:'광산' },
  { id:'CAPITOL', text:'국회의사당'},
  { id:'TAX_OFFICE', text:'세무서'},
  { id:'CENTRAL_BANK', text:'중앙은행'},
  { id:'INSUARANCE_COMPANY', text:'보험 회사'},
  { id:'WEDDING_HALL', text:'예식장'},
  { id:'DONATION_HOUSE', text:'기부의 집'},
  { id:'CONSUMER_PROTECTION_AGENCY', text:'소비자 보호청'},
  { id:'COMPANY', text:'회사'},
  { id:'STARTUP_COMPANY', text:'스타트업 기업'},
  { id:'PRESS', text:'언론사'},
  { id:'LIBRARY', text:'도서관'},
  { id:'OIL_RIG', text:'석유 플랫폼'},
  { id:'COUNSELING_CENTER', text:'상담 센터'},
  { id:'CHARGING_STATION', text:'충전소'},
] 
export const course_audience = [
  { id:'ALL', text:'전체대상' },
  { id:'PARENT_ONLY', text:'부모대상' },
  { id:'CHILD_ONLY', text:'자녀대상' },
]
export const course_difficulty = [
  { id:1, text:'입문' },
  { id:2, text:'초급' },
  { id:3, text:'중급' },
  { id:4, text:'상급' },
  { id:5, text:'고급' },
]
// 퀘스트
export const quest_type = [
  { id:'QUIZ', text:'퀴즈' },
  { id:'MISSION', text:'미션' },
  // { id:'KIT', text:'키트' },
  // { id:'CLASS', text:'강좌' },
]
export const quest_lesson = [
  { id:'NONE', text:'레슨 없음' },
  { id:'BEFORE', text:'인트로 레슨' },
  { id:'AFTER', text:'아웃트로 레슨' },
]
// 콘텐츠
export const content_quizArea = [
  { id:'KNOWLEDGE', text:'지식' },
  { id:'NEWS', text:'시사' },
  { id:'ANALYSIS', text:'분석력' },
  { id:'THINKING', text:'사고력' },
  { id:'VALUES', text:'가치관' },
]
export const content_applyCountry = [
  { id:'GLOBAL', text:'글로벌 적용 가능 퀴즈' },
  { id:'KOREA', text:'국내전용 퀴즈' },
]
// 퀴즈
export const quiz_quizContentType = [
  { id:'OX', text:'OX', icon:{ iconName:'ox', iconColor:'#fff', bgcolor:colorDataList.quiz_quizContentType[0].text } },
  { id:'SELECTONE', text:'양자택일', icon:{ iconName:'selectone', iconColor:'#fff', bgcolor:colorDataList.quiz_quizContentType[1].text } },
  { id:'ALL', text:'설문(모두정답)', icon:{ iconName:'all', iconColor:'#fff', bgcolor:colorDataList.quiz_quizContentType[2].text } },
]
export const quiz_ox = [
  { id:'O', text:'O' },
  { id:'X', text:'X' },
]
export const quiz_grade = [
  { id:'NORMAL', text:'일반배점' },
  { id:'HIGH', text:'고배점' },
]
// 미션
export const mission_quizContentType = [
  { id:'AUTOCHECK', text:'자동체크', icon:{ iconName:'autocheck', iconColor:'#fff', bgcolor:colorDataList.mission_quizContentType[0].text } },
  { id:'SELFCHECK', text:'자가체크', icon:{ iconName:'selfcheck', iconColor:'#fff', bgcolor:colorDataList.mission_quizContentType[1].text } },
];
export const mission_grade = [
  { id:'LOWEST', text:'최저점' },
  { id:'LOW', text:'낮은점수' },
  { id:'MEDIUM', text:'중간점수' },
  { id:'HIGH', text:'높은점수' },
  { id:'HIGHEST', text:'최고점' },
]

// 주식
export const stock_industry = [
  { id:'금융', text:'금융' },
  { id:'부동산', text:'부동산' },
  { id:'산업재', text:'산업재' },
  { id:'소재', text:'소재' },
  { id:'에너지', text:'에너지' },
  { id:'유틸리티', text:'유틸리티' },
  { id:'임의소비재', text:'임의소비재' },
  { id:'정보기술', text:'정보기술' },
  { id:'커뮤니케이션', text:'커뮤니케이션' },
  { id:'필수소비재', text:'필수소비재' },
  { id:'헬스케어', text:'헬스케어' },
  { id:'ETF', text:'ETF' },
];

export const stock_game_race_type = [
  { id:'READY', text:'준비' },
  { id:'ACCEPTING', text:'접수중' },
  { id:'DOING', text:'진행중' },
  { id:'DONE', text:'종료' },
]

// 직원관리 > 회원 권한
export const member_role = [
  { id:'ADMIN', text:'관리자' },
  { id:'USER', text:'일반' },
]

// 직원관리 > 회원 로그인 상태
export const member_login_status = [
  { id:'OK', text:'정상 로그인' },
  { id:'LOCKED', text:'로그인 잠금' },
  { id:'NEED_MFA', text:'MFA 확인 필요' },
]

// 직원관리 > 회원 로그인 상태 아이콘
export const member_login_status_icon = [
  { id:'OK', text:'login_ok' },
  { id:'LOCKED', text:'login_lock' },
  { id:'NEED_MFA', text:'login_setting' },
]

export const selectDataList = {
  // ** (더미)상태
  dummy_status : dummyStatusDataList,
  dummy_status2 : dummy2StatusDataList,
  schedule_type: schedule_type, // 스케줄 타입

  common_status: common_status,

  // 콘텐츠관리
  contents_status: contents_status, // 콘텐츠 상태
  // 지도
  map_type : map_type,
  map_extra_reward_type : map_extra_reward_type,
  // 코스
  // course_map : courseMapDataList, // MAP 장소
  course_audience : course_audience, // 학습대상
  course_difficulty : course_difficulty, // 코스난도
  // 퀘스트
  quest_type : quest_type, // 퀘스트 타입
  quest_lesson: quest_lesson, // 레슨 타입
  // 콘텐츠
  content_quizArea : content_quizArea, // 역량
  content_applyCountry : content_applyCountry, // 메타정보
  // 퀴즈
  quiz_quizContentType : quiz_quizContentType, // 퀴즈타입
  quiz_ox : quiz_ox, // 퀴즈 ox
  quiz_grade : quiz_grade, // 배점
  // 미션
  mission_quizContentType : mission_quizContentType, // 미션타입
  mission_grade: mission_grade, // 배점

  // 주식
  stock_industry : stock_industry, // 종목타입
  stock_game_race_type: stock_game_race_type, // 모의투자 레이스 타입

  member_role : member_role, // 회원 권한
  member_login_status : member_login_status, // 회원 로그인 상태
  member_login_status_icon : member_login_status_icon, // 회원 로그인 상태
}